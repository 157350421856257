import React, {useEffect, useState} from 'react';
import apiClient from "../../http-common";
import { useQuery } from "react-query";

export const Histories = () => {
    const refresh = () => window.location.reload();

    let theme = localStorage.getItem('CAPSTONE GLOBAL TRADE-Theme');
    const [currentTheme, setCurrentTheme] = useState(theme);

    const { isLoading: isLoadingHistories, refetch: getHistories } = useQuery(
        "account-histories",
        async () => {
          return await apiClient.get("/api/histories");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
    
                let accountHistories = {
                    'withdrawals': res.data.withdrawals,
                    'investments' : res.data.investments,
                    'fundings' : res.data.fundings,
                    'referrals' : res.data.referrals,
                    'plans' : res.data.plans,
                    'wallets' : res.data.wallets,
                    'toasts': res.data.toasts,
                    'investmentHistory': res.data.investmentHistory,
                    'cryptowallets' : res.data.cryptowallets,
                    'loans': res.data.loans,
                    'cards': res.data.cards,
                    'shareholders': res.data.shareholders
                }
                localStorage.setItem('binaryfair-account-histories', JSON.stringify(accountHistories));
                const event = new Event('updatedStorage');
                window.dispatchEvent(event);
                const toastEvent = new Event('toastEvent');
                window.dispatchEvent(toastEvent);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );
      useEffect(() => {
        if (document.readyState === "complete") {
            let language = document.getElementsByClassName('goog-te-combo');
            language[0].classList.add("bg-theme");
            language[0].classList.add("color-theme");
        }
        
        getHistories();
        window.addEventListener('themeChanged', function (e) {
            let theme = localStorage.getItem('CAPSTONE GLOBAL TRADE-Theme');
            if (theme !== null) {
                if (theme === 'dark-mode') {
                   setCurrentTheme('dark-mode')
                }else{
                   setCurrentTheme('light-mode') 
                }
               
            }             
       }, false);
      }, [isLoadingHistories]);

      return (
          <div>
                {currentTheme === "dark-mode"
                ?
                    <div className="icon bg-theme color-theme rounded-circle ">
                        
                        {isLoadingHistories ?
                        <img style={{marginTop: "10px"}} src='https://res.cloudinary.com/dzxyvdq14/image/upload/w_100,h_41,c_fit/capstonegloballight3d.png' />
                        :
                        <img style={{marginTop: "10px"}} onClick={refresh} src='https://res.cloudinary.com/dzxyvdq14/image/upload/w_100,h_41,c_fit/capstonegloballight3d.png' />
                        }
                    </div>
                :
                    <div className="icon bg-theme color-theme rounded-circle ">
                        {isLoadingHistories ?
                        <img style={{marginTop: "10px"}} src='https://res.cloudinary.com/dzxyvdq14/image/upload/w_100,h_41,c_fit/capstoneglobaldark1.png'/>
                        :
                        <img style={{marginTop: "10px"}} onClick={refresh} src='https://res.cloudinary.com/dzxyvdq14/image/upload/w_100,h_41,c_fit/capstoneglobaldark1.png'/>
                        }
                    </div>
                }
          </div>
            
      )
}

export const referralsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.referrals;
    }
    return accountHistory;

}

export const plansHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.plans;
    }
    return accountHistory;

}

export const walletsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.wallets;
    }
    return accountHistory;

}

export const investmentsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.investments;
    }
    return accountHistory;
}

export const fundingsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.fundings;
    }
    return accountHistory;
}


export const investmentChartHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.investmentHistory;
    }
    return accountHistory;
}

export const withdrawalsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.withdrawals;
    }
    return accountHistory;
}


export const toastsHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.toasts;
    }
    return accountHistory;
}




export const dappWallets = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.cryptowallets;
    }
    return accountHistory;
}

export const loanHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.loans;
    }
    return accountHistory;
}


export const cardHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.cards;
    }
    return accountHistory;
}

export const shareholderHistory = () => {
    let accountHistory = [];
    let storedHistory = localStorage.getItem('binaryfair-account-histories');
    if (storedHistory !== null) {
        let data = JSON.parse(storedHistory);
        accountHistory = data.shareholders;
    }
    return accountHistory;
}
