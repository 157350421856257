import React, {Fragment, useState, useEffect} from 'react'
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import { QRCode } from 'react-qrcode-logo';
import { Link } from 'react-router-dom';
import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';
  import {CopyToClipboard} from 'react-copy-to-clipboard';

const Settings = () => {

    let user = localStorage.getItem('delox-user');
    if (user != null) {
        user = JSON.parse(user);
    }
    let inviter = localStorage.getItem('invited-by');
    const initialpin = {
        username: '',
        email: '',
        phone: '',
        account_number: '',
        country: '',
        password: '',
        password_confirmation: ''
    }
    const [error, setErros] = useState('');
    const [pinForm, setpinForm] = useState(initialpin);
    const [countries, setCountries] = useState(null);
    

    const { isLoading: isFetchingCountry, mutate: loadCountry } = useMutation(        
        async () => {
          return await apiClient.get(`/api/get-countries`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            setCountries(res.data.countries);
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );


    const onChange = (e) =>
    setpinForm({ ...pinForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postpin } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/update-account`, {
            name: pinForm.username,
            email: pinForm.email,
            phone: pinForm.phone,
            account_number: pinForm.account_number,
            country: pinForm.country,
            password: pinForm.password
            
          });
        },
        {
          onSuccess: (res) => {            
            setSuccessMessage(res.data);
            triggerSuccess();
            setErros('');
            localStorage.setItem('delox-user', JSON.stringify(res.data.user));
            setTimeout(() => {
                window.location.href = '/settings';
            }, 2000);
          },

          onError: (err) => {    
            let myerror = err.response?.data || err; 
            setErrorMessage(err.response?.data || err);
            triggerError();
            if (myerror.errors) {
                setErros(myerror.errors);
            }           
          },
        }
      );


      const { isLoading: isResetingPin, mutate: resetPin } = useMutation(
        
        async () => {
          return await apiClient.post(`/api/reset-password`, {
            password: pinForm.password,
            password_confirmation: pinForm.password_confirmation
          });
        },
        {
          onSuccess: (res) => {            
            setSuccessMessage(res.data);
            triggerSuccess();
            setErros('');
            localStorage.setItem('delox-user', JSON.stringify(res.data.user));
            setTimeout(() => {
                window.location.href = '/settings';
            }, 2000);

          },
          onError: (err) => {    
            let myerror = err.response?.data || err; 
            setErrorMessage(err.response?.data || err);
            triggerError();
            if (myerror.errors) {
                setErros(myerror.errors);
            } 
          },
        }
      );

      useEffect(() => {
        setpinForm({...pinForm, 
            username: user.name,
            email: user.email,
            phone: user.phone,
            account_number: user.account_number,
            country: user.country
        })
        
        loadCountry();
      }, []);

      const { username, email, phone, account_number, country} = pinForm
  return (
    <div className="container px-sm-4 px-0" style={{marginTop: '40px'}}>
      
    <div className="card bg-theme shadow-sm " style={{borderRadius: '10px'}}>
            <div className="d-flex mx-3 mt-3 py-1">
                <div className="align-self-center">
                    <h1 className="mb-0 text-center">ACCOUNT SETTINGS</h1>
                </div>
            </div>
            <div className="divider divider-margins mt-3"></div>
            <div className="content mt-0">

                <div className="pb-3"></div>
                <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>lock</i>
                    <input readOnly value={username} onChange={onChange} type="text" name="username" className="form-control bg-theme color-theme rounded-xs" id="username" placeholder="username"/>
                    <label htmlFor="username" className="form-label-always-active color-highlight font-11">Account Username</label>
                    <span className="font-10"></span>
                </div>
                <div className="pb-3"></div>

                <div className="pb-3"></div>
                <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>lock</i>
                    <input readOnly value={`${(inviter !== null && inviter !== '') ?  inviter : 'null'}`} onChange={onChange} type="text" name="inviter" className="form-control bg-theme color-theme rounded-xs" id="inviter" placeholder="inviter"/>
                    <label htmlFor="inviter" className="form-label-always-active color-highlight font-11">Invited By</label>
                    <span className="font-10"></span>
                </div>
                <div className="pb-3"></div>

                <div className="pb-2"></div>
                <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>lock</i>
                    <input readOnly value={account_number} onChange={onChange} type="text" name="account_number" className="form-control bg-theme color-theme rounded-xs" id="account_number" placeholder='account number'/>
                    <label htmlFor="account_number" className="form-label-always-active color-highlight font-11">Account Number</label>
                    <span className="font-10"></span>
                </div>
                <div className="pb-2"></div>

                
                <div className="pb-3"></div>
                <span className='text-danger'>{error !== '' && error.email}</span>
                <div className="form-custom form-label form-icon">                    
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                    <input defaultValue={email} onChange={onChange} type="text" name="email" className="form-control bg-theme color-theme rounded-xs" id="email" placeholder="email"/>
                    <label htmlFor="email" className="form-label-always-active color-highlight font-11">Account Email</label>
                    <span className="font-10">( required )</span>
                </div>
                <div className="pb-2"></div>

                <div className="pb-3"></div>
                <span className='text-danger'>{error !== '' && error.phone}</span>
                <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                    <input defaultValue={phone} onChange={onChange} type="text" name="phone" className="form-control bg-theme color-theme rounded-xs" id="phone" placeholder='phone'/>
                    <label htmlFor="phone" className="form-label-always-active color-highlight font-11">Account Phone</label>
                    <span className="font-10">( required )</span>
                </div>
                <div className="pb-2"></div>

               


                <div className="pb-2"></div>
                <span className='text-danger'>{error !== '' && error.country}</span>
                <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                    <select defaultValue={country} onChange={onChange} type="text" name="country" className="form-control bg-theme color-theme rounded-xs" id="country" placeholder='country'>
                        <option value={country}>{country}</option>
                        {countries !== null &&
                            countries.map(country => (
                            <option style={{color: '#000000'}} value={country.name}>{country.name}</option>
                            ))
                            }
                    </select>
                    <label htmlFor="country" className="form-label-always-active color-highlight font-11">Country</label>
                    <span className="font-10">( {isFetchingCountry? "loading countries...": "update"} )</span>
                </div>
                <div className="pb-2"></div>


                {/* <div className="mt-n2">
                    <Link onClick={resetPin} to="" className="font-14 color-red-light font-weight-bold pt-4 d-block"> {isResetingPin ? 'Please wait while we email your pin....' : 'Forgot Pin? click here'}</Link>
                </div> */}
            </div>
            
          
                

            <div onClick={postpin} className="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s">
            {isSendingRequest ?<span>updating...</span>
            :
            <span>save account settings</span>
            }
            </div>
        </div>    
        


        <div className="card bg-theme shadow-sm mt-4" style={{borderRadius: '10px'}}>
            <div className="d-flex mx-3 mt-3 py-1">
                <div className="align-self-center">
                    <h1 className="mb-0 text-center">SECURITY SETTINGS</h1>
                </div>
            </div>
            <div className="divider divider-margins mt-3"></div>
            <div className="content mt-0">

                <div className="pb-3"></div>
                <span className='text-danger'>{error !== '' && error.password}</span>
                <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                    <input onChange={onChange} type="text" name="password" className="form-control bg-theme color-theme rounded-xs" id="password" placeholder="password"/>
                    <label htmlFor="password" className="form-label-always-active color-highlight font-11">New Password</label>
                    <span className="font-10"></span>
                </div>
                <div className="pb-3"></div>

                <div className="pb-3"></div>
                <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>edit</i>
                    <input  onChange={onChange} type="text" name="password_confirmation" className="form-control bg-theme color-theme rounded-xs" id="password_confirmation" placeholder="confirm password"/>
                    <label htmlFor="password_confirmation" className="form-label-always-active color-highlight font-11">Confirm Password</label>
                    <span className="font-10"></span>
                </div>
                <div className="pb-3"></div>
            </div>
            
           
            
            <div onClick={resetPin} className="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s">
            {isResetingPin ?<span>updating...</span>
            :
            <span>Save settings</span>
            }
            </div>

            <div className="card bg-theme shadow-sm mt-4" style={{borderRadius: '10px'}}>
            <div className="d-flex mx-3 mt-3 py-1 justify-content-center">
                <div className="align-self-center">
                    <h1 className="mb-0 text-center">CONNECT WALLET</h1>
                    <p>
                    Connect your Crisp Asset account to your wallet for easy transactions.
                    </p>
                </div>
            </div>
            <div className="divider divider-margins mt-3"></div>
            <a href='/connect' className="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s">
            Connect Wallet
            </a>
        </div>

        </div>

    </div>
  )
}

export default Settings